<template>
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill=""
      d="M1.74264 0.658043C2.0458 0.658043 2.34897 0.773309 2.58029 1.00463L8.05859 6.48372L13.5369 1.00463C13.9995 0.542777 14.7496 0.542777 15.2122 1.00463C15.6741 1.46728 15.6741 2.2173 15.2122 2.67994L8.89625 8.99589C8.4336 9.45775 7.68358 9.45775 7.22094 8.99589L0.904985 2.67994C0.44313 2.21729 0.44313 1.46727 0.904985 1.00463C1.13631 0.773309 1.43947 0.658043 1.74264 0.658043Z"
    />
  </svg>
</template>
